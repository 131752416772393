@import '_reset.scss';

/******************************************************************************
 * Sizes
*******************************************************************************/
$max-width: 1024px;
$min-width: 768px;
$mob-width: 480px;
$font-base: 0.875rem;
$font-small: 0.75rem;
$font-medium: 1rem;

/******************************************************************************
 * Colors
*******************************************************************************/
$black: #000;
$white: #fff;
$grey: #555;
$grey-dark: #333;
$grey-black: #111;
$green: #10ff59;
$yellow: #eefd02;
$red: #f30541;
$pink: #f26d7d;
$purple: #6441a4;
$blue: #1795d4;
$teal: #2affea;

/******************************************************************************
 * Global
*******************************************************************************/
body {
  color: $black;
  font-family: "panicsans", monospace;
  font-size: $font-base;
  line-height: 1.7142857143;

  &.top {
    color: $pink;
    background-color: $grey-black;
  }

  &.best {
    color: $grey-dark;
    background-color: $pink;
  }

  &.ask {
    color: $green;
    background-color: $purple;
  }

  &.show {
    color: $teal;
    background-color: $grey-dark;
  }

  &.jobs {
    color: $white;
    background-color: $black;
  }

  &.info {
    background-color: $teal;
    color: $purple;
  }
}

strong {
  font-family: "panicsansbold";
}

.-anim {
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: opacity 400ms ease-out, transform 400ms ease-out;

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.-ul {
  border-bottom: 1px solid $black;

  .top & {
    border-bottom-color: $pink;
  }

  .best & {
    border-bottom-color: $grey-dark;
  }

  .ask & {
    border-bottom-color: $green;
  }

  .show & {
    border-bottom-color: $teal;
  }

  .jobs & {
    border-bottom-color: $white;
  }

  .info & {
    border-bottom-color: $purple;
  }
}

.-i {
  display: inline;
  
  svg {
    width: 16px;
    height: 16px;
    transform: translate3d(10px, 1px, 0);

    @media (max-width: $mob-width) {
      width: 16px;
      height: 16px;
    }

    .top & {
      fill: $pink;
    }

    .best & {
      fill: $grey-dark;
    }

    .ask & {
      fill: $green;
    }

    .show & {
      fill: $teal;
    }

    .jobs & {
      fill: $white;
    }
  }
}

.-btn {
  appearance: none;
  border: 0;
  outline: 0;
  font-size: $font-base;
  line-height: 1;

  @media (max-width: $mob-width) {
    font-size: $font-small;
  }
}