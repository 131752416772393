@import '_shared.scss';

.punxy {
  max-width: $max-width;
  margin: 0 auto;
}

.punxy__navi,
.punxy__mast,
.punxy__feed,
.punxy__header {
  padding: 32px;

  @media (max-width: $min-width) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.punxy__navi,
.punxy__mast {
  @media (max-width: $min-width) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.punxy__navi,
.punxy__ctrl {
  ul,
  li {
    display: inline-block;
  }
}

/******************************************************************************
 * <Header />
*******************************************************************************/
.punxy__header {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.punxy__logo {
  display: block;

  svg {
    height: 32px;
    width: auto;

    @media (max-width: $min-width) {
      height: 24px;
    }

    .top & {
      fill: $pink;
    }

    .best & {
      fill: $grey-dark;
    }

    .ask & {
      fill: $green;
    }

    .show & {
      fill: $teal;
    }

    .jobs & {
      fill: $white;
    }

    .info & {
      fill: $purple;
    }
  }
}

/******************************************************************************
 * <Navi />
*******************************************************************************/
.punxy__navi {
  font-size: $font-medium;
  line-height: 2.3333333333;
  margin-top: 32px;

  @media (max-width: $mob-width) {
    display: flex;
  }

  ul {
    white-space: nowrap;
    overflow-y: auto;

    @media (max-width: $mob-width) {
      width: 100%;
    }

    li {
      &:after {
        content: "\00a0/\00a0";
      }

      &:last-child:after {
        content: "";
      }
    }
  }
}

.punxy__link {
  &.is-active {
    font-family: "panicsansbold";
  }
}

/******************************************************************************
 * <Masthead />
*******************************************************************************/
.punxy__mast {
  font-size: 1.5rem;
  line-height: 1.75;

  @media (max-width: $mob-width) {
    font-size: 1.3125rem;
  }
  
  h1 {
    max-width: 570px;
  }

  a {
    font-family: "panicsansbold";
  }
}

/******************************************************************************
 * <info />
*******************************************************************************/
.punxy__info {
  padding: 32px;

  @media (max-width: $min-width) {
    padding-left: 16px;
    padding-right: 16px;
  }

  p + p {
    margin-top: 64px;
  }
}

.punxy__friendsters {
  padding: 64px 0;
  max-width: 420px;
  width: 100%;

  svg {
    width: 100%;
  }
}

/******************************************************************************
 * <Feed>...</Feed>
*******************************************************************************/
.punxy__ctrl,
.punxy__meta,
.punxy__load {
  line-height: 3;
}

.punxy__ctrl {
  background-color: rgba(255, 255, 255, 0.9);
  position: sticky;
  padding: 32px 0;
  top: 0;
  z-index: 1;

  @media (max-width: $min-width) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .top & {
    background-color: rgba(17, 17, 17, 0.9);
  }

  .best & {
    background-color: rgba(242, 109, 125, 0.9);
  }

  .ask & {
    background-color: rgba(100, 65, 164, 0.9);
  }

  .show & {
    background-color: rgba(51, 51, 51, 0.9);
  }

  .jobs & {
    background-color: rgba(0, 0, 0, 0.9);
  }

  ul {
    display: flex;
  }

  li {
    cursor: pointer;
  }

  li.is-active {
    font-family: "panicsansbold";
  }

  // Anchors `refresh` UI to the right of Controls
  li:last-child {
    margin-left: auto;
  }
}

.punxy__btn {
  background-color: $black;
  color: $white;
  font-family: "panicsansbold";
  padding: 16px;
  cursor: pointer;
  margin-bottom: 32px;

  .top & {
    background-color: $pink;
    color: $grey-black;
  }

  .best & {
    background-color: $grey-dark;
    color: $pink;
  }

  .ask & {
    background-color: $green;
    color: $purple;
  }

  .show & {
    background-color: $teal;
    color: $grey-dark;
  }

  .jobs & {
    background-color: $white;
    color: $black;
  }
}

.punxy__btns {
  display: flex;
  margin: 0 -16px;

  @media (max-width: $min-width) {
    margin: 0 -8px;
  }

  div {
    margin: 0 16px;

    @media (max-width: $min-width) {
       margin: 0 8px;
    }
  }

  button {
    margin-bottom: 0;
  }

  div:last-child button {
    .top & {
      background-color: $pink;
      color: $grey-black;
    }

    .best & {
      background-color: $grey-dark;
      color: $pink;
    }

    .ask & {
      background-color: $green;
      color: $purple;
    }

    .show & {
      background-color: $teal;
      color: $grey-dark;
    }

    .jobs & {
      background-color: $white;
    }
  }
}

.punxy__list,
.punxy__load {
  padding: 32px 0;
}

.punxy__load {
  @media (max-width: $min-width) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.punxy__item {
  padding: 32px 0;

  @media (max-width: $mob-width) {
    padding: 16px 0;
  }
}

.punxy__list,
.punxy__feed {
  padding-top: 0;
}

.punxy__attr,
.punxy__load div {
  display: inline-block;
}

.punxy__title {
  font-size: 1.125rem;
  line-height: 2;
  font-family: "panicsansbold";
  text-transform: lowercase;

  @media (max-width: $mob-width) {
    font-size: $font-medium;
  }
}

/******************************************************************************
 * <Modal />
*******************************************************************************/
.punxy__modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111001001;

  .top & {
    background-color: rgba(17, 17, 17, 0.8);
    color: $pink;
  }

  .best & {
    background-color: rgba(242, 109, 125, 0.8);
    color: $grey-dark;
  }

  .ask & {
    background-color: rgba(100, 65, 164, 0.8);
    color: $green;
  }

  .show & {
    background-color: rgba(51, 51, 51, 0.8);
    color: $teal;
  }

  .jobs & {
    background-color: rgba(0, 0, 0, 0.8);
    color: $white;
  }

  .punxy__mast {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .punxy__modal__wrap {
    max-width: $max-width;
    padding: 32px;
    overflow: hidden;
    background-color: $white;

    @media (max-width: $min-width) {
      padding: 16px;
    }

    .top & {
      background-color: $grey-black;
    }

    .best & {
      background-color: $pink;
    }

    .ask & {
      background-color: $purple;
    }

    .show & {
      background-color: $grey-dark;
    }

    .jobs & {
      background-color: $black;
    }
  }
}
